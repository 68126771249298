<template>

  <v-form v-if="item" autocomplete="off">
    <v-row>
        <v-col md=7>

          <v-text-field
            id="nameInput"
            name="name"
            v-model="item.name"
            label="Nome"
            hint="pode conter acentos e espaços"
          ></v-text-field>

          <v-text-field
            id="valueInput"
            name="value"
            v-model="item.value"
            label="Valor"
          ></v-text-field>
          
        </v-col>

        <v-col>

        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>
    </v-row>

  </v-form>

</template>

<script>
    import BackButton from '../../components/BackButton.vue'
  import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'RoleForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item'],
    methods: {
      submit () {
        EventBus.$emit('role-submitted')
      }
    }
  }
</script>
