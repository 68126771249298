<template>
    <v-row>
        <v-textarea
            class="text-code"
            rows="12"
            row-height="10"
            v-model="str"></v-textarea>

    </v-row>


</template>
<script>
  const {EventBus} = require('../../addons/event-bus.js')
      export default {
        props: ['raw', 'name'],
        computed: {
            str: {
               get(){
                   return JSON.stringify(this.raw, undefined, 4)
               },
               set(newStr){
                 return newStr
               }
            }
        },
        methods: {
          submit () {
            const event = this.name + '-submitted'
            EventBus.$emit(event)
          }
        }
    }
</script>
<style>
    .text-code { font-family: "Courier New"; }
</style>