<template>
<v-container fluid>
  <v-row>
      <action-bar entity="role"></action-bar>
      <v-col cols=11>
        <v-card>
          <v-card-title>
            <h2 class="title">Papéis de Usuário</h2>
          </v-card-title>
        </v-card>

      <div v-if="items">
            <default-table
              v-if="!$route.params.action"
              :items="items"
              :headers="headers"
              endpoint="role"
              entity="role"
              :actions="['edit', 'delete']"
              :statusKeys="[]"
              name="Papéis"
            >
            </default-table>
      </div>

      <div v-if="item">

            <raw-api-response
              :raw="raw_item"
              v-if="$route.params.action === 'dev' && $route.params.id"
            ></raw-api-response>

            <role-form :item="item" v-if="($route.params.action === 'edit' && $route.params.id) || ($route.params.action === 'create' && !$route.params.id)"></role-form>
      </div>
      
      <div v-if="$store.state.loading">  
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="'/static/illustrations/' + image + '.svg'"
            width="100%"
            icon
          />
      </div>

    </v-col>
  </v-row>
</v-container>
</template>
<script>

const mixins = require('../../addons/mixins.js')
const {displayRole, processRole, roleModel, roleSchema} = require('../descriptors.js')

import ActionBar from '../../components/ActionBar'
import RoleForm from '../components/RoleForm'
import DefaultTable from '../../components/DefaultTable'
import RawApiResponse from '../components/RawApiResponse'

export default {
    name: 'Role',
    components: {
        ActionBar,  
        DefaultTable,
        RoleForm,
        RawApiResponse,
    },
    mixins: [
      mixins.defaultEvents,
      mixins.defaultMethods,
      mixins.routeEvents,
      mixins.routeMethods,
    ],
    data() {
      return {
        'image': '',
        'name': 'Papel de Usuário',
        'plural_name': 'Papéis de Usuário',
        'entity': 'role',
        'addAction': 'addEntity',
        'updateAction': 'updateEntity',
        'removeAction': 'removeEntity',
        'loadAction': 'getEntity',
        'loadAllAction': 'getEntities',
        'redir': '/role'
      }
    },
    mounted() {
      this.runLoad()
    },
    computed: {
      pk: function() {
        return this.$route.params.id
      },
      view: function() {
        return this.$route.params.action
      },
      roles: function() {
        return this.$store.getters.profileRoles
      },
      processed: function() {
        return processRole
      },
      schema: function() {
        return roleSchema
      },
      raw_items: function() {
        return this.$store.getters.roleList
      },
      items: function() {
        return this.$store.getters.roleList.map(i => {return displayRole(i, this.$store.getters.dictionary)})
      },
      raw_item: function() {
        if (this.$route.params.action === 'create') {
          return roleModel()
        }
        return this.$store.getters.currentRole
      },
      item: function() {
        if (this.$route.params.action === 'create') {
          return roleModel()
        }
        return displayRole(this.$store.getters.currentRole, this.$store.getters.dictionary)
      },
      headers: function() {
        return [
          { text: 'Nome', value: 'name'},
          { text: 'Valor', value: 'value'},
          { text: 'Ações', sortable: false }
        ]
      }
    },
  }
</script>