<template>
  <div v-if="items">
    <p>mostrando {{items.length}} {{name}}(s)</p>

    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtro"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col md="6">
        &nbsp;
      </v-col>
      
      <v-col md="2">
        &nbsp;
      </v-col>

    </v-row>
    <br>
    <br>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:item="row">
          <tr>
            <td v-for="key in keys" v-bind:key="key">
              <div v-if="!statusKeys.includes(key)" v-html="row.item[key]"></div>
              <status-table-display v-if="statusKeys.includes(key)" :dictionaryKey="row.item[key]"></status-table-display>
            </td>
            <td>
              <view-button 
                v-if="actions.includes('view')"
                view="small"
                :path="'/' + endpoint + '/view/' + row.item.id"
              ></view-button>

              <edit-button 
                v-if="actions.includes('edit')"
                view="small"
                :path="'/' + endpoint + '/edit/' + row.item.id"
              ></edit-button>

               <delete-dialog
                  v-if="actions.includes('delete')"
                  view="small"
                  :event="entity + '-removal-submitted'"
                  :pk="row.item.id"
               ></delete-dialog>
            </td>
          </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import EditButton from './EditButton.vue'
  import ViewButton from './ViewButton.vue'
  import DeleteDialog from './DeleteDialog.vue'
  import StatusTableDisplay from './StatusTableDisplay.vue'
  const {EventBus} = require('../addons/event-bus.js')

  export default {
    components: {
      ViewButton,
      EditButton,
      StatusTableDisplay,
      DeleteDialog,
    },
    name: 'DefaultTable',
    props: ['name', 'headers', 'items', 'entity', 'endpoint', 'actions', 'statusKeys', 'filters'],
    data() {
      return {
        search: '',
      }
    },
    computed: {
      keys() {
        const keys = this.headers.filter(i => { return i.value }).map(i => { return i.value ? i.value : false })
        return keys && keys.length > 0 ? keys : []
      }
    },
    methods: {
        filterTable(key, value) {
          let payload = {}
          payload[key] = value
          EventBus.$emit(this.endpoint.replace('_', '-') + '-search-submitted', payload)
        },
    }
  }

</script>

<style scoped>
td {
  height:  100px !important;
}
</style>