<template>
    <span>
        <v-btn
          id="backButtonLg"
          v-if="!view || view === 'big'"
          color="default"
          dark
          @click="$router.go(-1)"
        >
          Voltar
        </v-btn>
    
        <v-btn
          id="backButtonSm"
          v-if="view === 'small'"
          class="mx-2"
          fab
          dark
          small
          color="default"
          @click="$router.go(-1)"
        >
          <v-icon dark>
            mdi-arrow-left
          </v-icon>
        </v-btn>
    </span>
</template>
<script>
  export default {
    props: ['view']
}
</script>
